<!-- Finder particular de Atlas -->

<template>
  <div class="atlas_F" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">
        <base_Header 
          :componenteTipo="componenteTipo"
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div>
        
      <!-- Filtro -->
      <div class="contenedor">        
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div class="filtro contenido">

            <div style="display:flex; width:35rem">
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.atlas.value"
                :label="schema.ctrls.atlas.label"
                @keypress.13="event_search('buscarAtlas')">                
              </v-text-field>

              <v-btn
                v-bind="$cfg.btn.busca"
                @click="event_search('buscarAtlas')">              
                <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
              </v-btn>
            </div>

          </div>
        </div>        

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones_grid="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'atlas_F',
        stName:'stFatlas',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,
        headers: {}
      };
    },
  
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;
        
        // entorno
        this.Entorno.header.titulo = "Busqueda de Atlas";        
        this.headers= {
          header: [
            { text: "Nombre", value: "name" },
            { text: "Acciones", value: "acciones", sortable: false, width: "auto" }
          ]
        }

        this.Entorno.grid.headers = this.set_headers();
        this.Entorno.header.style += ";min-width:40rem"; 
        
        // compruebo si lleva mto por el tipo de componente
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'atlas_M';
      },

     
      // Recoge eventos de busqueda
      event_search(busqueda = "", header) {
        console.log("*** event_search. atlas_F: busqueda:" + busqueda + ", header:" + header + " ***");
        this.buscarExec(busqueda, header);
      },
     
    }
  };
</script>


